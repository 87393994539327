import React from 'react';
import { Link } from 'gatsby';
import firstImage from '../images/full-logo.png';
import './index.css';
import '../pages/hire-a-dev/responsiveHire.css';

const SuccessPage = () => {
  return (
    <div className="main-hire-page">
      <div className="hire-container">
        <div className="first-page-questions">
          <Link className="coetic-x-logo" to="/">
            <img className="coetic-x" src={firstImage} alt="coetic-x logo" />
          </Link>
          <div className="questions">
            <div className="success-icon">
              <p className="material-icons check">
                <span>check_circle</span>
              </p>
            </div>
            <div className="success-text">
              <p className="thanks-message">Thank you so much for your message! </p>
              <p className="respond-message">Our team will respond to you within 24 hours.</p>
            </div>
          </div>
          <div className="hire-page-line"></div>
          <div className="hire-button">
            <Link to="/" className="button-next">
              <span> Go to the main page</span>
              <p className="material-icons arrow ">arrow_circle_right</p>
            </Link>
          </div>
        </div>
        <div className="first-page-logo">
          <svg id="Group_73" data-name="Group 73" xmlns="http://www.w3.org/2000/svg" width="50.206" height="65.219" viewBox="0 0 50.206 65.219">
            <defs>
              <clipPath id="clip-path">
                <path
                  id="Path_1"
                  data-name="Path 1"
                  d="M95.148,550.594v7.247l6.276,3.623,6.276,3.623,6.276,3.623v-7.246L107.7,557.84l-6.276-3.624-6.276-3.623,6.276-3.623v-7.247l-6.276,3.623-6.276,3.624L82.6,550.594v-7.247l-6.276-3.623L70.045,536.1l-6.276-3.623v7.246l6.276,3.623,6.276,3.624,6.276,3.623-6.276,3.623v7.247L82.6,557.84l6.276-3.624Zm6.276,18.117-6.276,3.623-6.276,3.623L82.6,572.334l-6.276-3.624-6.276-3.623V550.594l-6.276-3.623v21.74l6.276,3.624,6.276,3.623L82.6,579.58l6.276,3.623,6.276-3.623,6.276-3.623,6.276-3.623ZM107.7,536.1v7.246l6.276-3.623v-7.246l-6.276-3.624-6.276-3.623-6.276-3.623-6.276-3.623L82.6,521.607l-6.276,3.623-6.276,3.623,6.276,3.624,6.276-3.624,6.276-3.623,6.276,3.623,6.276,3.624Z"
                  transform="translate(-63.769 -517.984)"
                  fill="#fff"
                  clip-rule="evenodd"
                />
              </clipPath>
            </defs>
            <g id="Group_1" data-name="Group 1" clip-path="url(#clip-path)">
              <rect id="Rectangle_1" data-name="Rectangle 1" width="50.206" height="65.22" transform="translate(0 0)" fill="#fff" />
            </g>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
